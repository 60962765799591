import { ReactNode } from 'react';
import styles from './Tag.module.scss';

interface TagProps {
  children: ReactNode;
  style?: Object;
};

export default function ProjectTag({ children, style }: TagProps) {
  return (
    <span className={styles.tag} style={style}>
      { children }
    </span>
  );
};
