import styles from './About.module.scss';

export default function About() {
  return (
    <section className={`${styles.about} content`} id="about">
      <div className={styles.left}>
        <h2>A Bit About Me</h2>
        <p>
          I&apos;m a young student that mainly does web development using the full web stack. I particularly love creating ReactJS frontends along with backends in Node.js or PHP Laravel and am the creator of <a href="https://mizarbot.xyz" target="_blank" rel="noopener noreferrer">Mizar</a>, a Discord bot in over 900 servers.
        </p>
        <p>
          In 2019, I was a winner of <a href="https://codein.withgoogle.com/" target="_blank" rel="noopener noreferrer">Google Code-in</a> for my contributions towards open source project <a href="https://circuitverse.org" target="_blank" rel="noopener noreferrer">CircuitVerse</a> and its <a href="https://docs.circuitverse.org" target="_blank" rel="noopener noreferrer">documentation</a> and <a href="https://learn.circuitverse.org" target="_blank" rel="noopener noreferrer">education</a> initiatives. I&apos;m also <a href="https://www.giac.org/certifications/certified-incident-handler-gcih/" target="_blank" rel="noopener noreferrer">GCIH Certified</a>, advancing to the Elite round of HM Government&apos;s cyber security programme <a href="https://www.joincyberdiscovery.com/" target="_blank" rel="noopener noreferrer">CyberDiscovery</a> for two consecutive years.
        </p>
        <p>
          I&apos;m a Software Developer at <a href="https://shockbyte.com" target="_blank" rel="noopener noreferrer">Shockbyte</a> and part of the Executive Team at <a href="https://www.youthstem2030.org" target="_blank" rel="noopener noreferrer">Youth STEM 2030</a>, in addition to being a founder of <a href="https://www.vanadiumgames.co.uk" target="_blank" rel="noopener noreferrer">Vanadium Games</a> in 2018.
        </p>
      </div>
      <div className={styles.right}>
        <div className={styles.rightBackground} />
        <h3>Qualifications</h3>
        <ul>
          <li>GIAC Certified Incident Handler</li>
          <li>GIAC Advisory Board Member</li>
          <li>Microsoft Certified Azure Administrator Associate</li>
        </ul>

        <h3>Skills</h3>
        <ul>
          <li>Web & Software Development</li>
          <li>System Administration</li>
          <li>Developer Operations</li>
          <li>Cyber Security</li>
          <li>Technical Theatre & Events</li>
        </ul>
      </div>
    </section>
  );
};