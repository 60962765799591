import Image from 'next/image'
import styles from './Hero.module.scss';
import Particles from '../home/Particles';
import ProfilePicture from '../../public/profile-picture.jpg';

export default function Hero() {
  return (
    <section className={`${styles.hero} content`} id="home">
      <Particles />

      <div className={styles.image}>
        <Image src={ProfilePicture} alt="A profile picture of Josh Heng" width={500} height={500} placeholder="blur" priority />
      </div>
      <div className={styles.introduction}>
        <div>
          <h1>“Hi, I&#x27;m Josh!”</h1>
          <p>Winner of Google Code-in and attendee of the Elite round of the UK Government&#x27;s flagship cyber security programme CyberDiscovery, I&#x27;m a young student interested in everything technology.</p>
          <p>I’m a highly motivated and have a passion for developing products and solutions. I love using computers to improve our lives and streamline inefficient workflows.</p>


          <ul>
            <li><em>Software Developer</em> at <a href="https://shockbyte.com" target="_blank" rel="noopener noreferrer">Shockbyte</a></li>
            <li><em>Treasurer</em> at <a href="https://www.warwicktechcrew.co.uk" target="_blank" rel="noopener noreferrer">Warwick Tech Crew</a></li>
            <li><em>Tech Officer</em> at <a href="https://uwcs.co.uk/" target="_blank" rel="noopener noreferrer">University of Warwick Computing Society</a></li>
            <li><em>Executive Team Member</em> at <a href="https://www.youthstem2030.org/youth-stem-matters" target="_blank" rel="noopener noreferrer">Youth STEM Matters</a></li>
            <li><em>Website, Social Media & Digital Media Manager</em> at <a href="https://minsterlaundry.co.uk" target="_blank" rel="noopener noreferrer">Minster Laundry</a></li>
            <li><em>Chief Technology Officer & Game Developer</em> at <a href="https://www.vanadiumgames.co.uk" target="_blank" rel="noopener noreferrer">Vanadium Games</a></li>
          </ul>
        </div>
      </div>
    </section>
  )
}