import { ReactNode } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import styles from './Card.module.scss';

interface CardProps {
  image?: {
    src: string;
    alt?: string;
    width?: number;
    height?: number;
    blurDataURL?: string;
  }
  url?: string;
  openUrlInNewTab?: Boolean;
  large?: Boolean;
  children: ReactNode;
  extraLinks?: ReactNode;
  className?: string;
};

export default function Card({ image, url, children, extraLinks = null, openUrlInNewTab = false, large = false, className = '' }: CardProps) {
  return (
    <article className={`${styles.card} ${url ? styles.anchorCard : ''} ${large ? styles.largeCard : ''} ${className}`}>
      { url ?
        <Link href={url}>
          <a className={styles.mainAnchor} target={openUrlInNewTab ? '_blank' : ''} rel={openUrlInNewTab ? 'noopener noreferrer' : ''}>
            <CardContent image={image} url={url}>
              { children }
            </CardContent>
          </a>
        </Link>
      :
        <CardContent image={image} url={url}>
          { children }
        </CardContent>
      }

      { extraLinks && extraLinks }
    </article>
  )
};

function CardContent({ image, children }: CardProps) {
  return (
    <>
      { image && <div><Image src={image.src} alt={image.alt} layout="responsive" width={image.width} height={image.height} blurDataURL={image.blurDataURL} placeholder="blur" /></div> }

      <div className={styles.content}>
        { children }
      </div>     
    </>
  );
};
