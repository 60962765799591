import ProjectTag from './ProjectTag';
import styles from './Project.module.scss';
import { BsGithub, BsGlobe } from 'react-icons/bs';
import ProjectData from '../../../types/project';
import Card from '../../cards/Card';

interface Project {
  project: ProjectData;
}

export default function Project({ project }: Project) {
  return (
    <Card url={project.url} openUrlInNewTab image={{ src: '', ...project.image, alt: `Image of ${project.name}` }} extraLinks={<>
      { project.links?.main && <a href={project.links.main} target="_blank" rel="noopener noreferrer" className={`${styles.linkIcon} ${styles.mainLink}`}>
        <BsGlobe /><span className="visually-hidden">Website</span>
      </a> }

      { project.links?.github && <a href={project.links.github} target="_blank" rel="noopener noreferrer" className={`${styles.linkIcon} ${styles.githubLink}`}>
        <BsGithub /><span className="visually-hidden">GitHub</span>
      </a> }
    </> }>
      <h3>{project.name}</h3>

      <p>{project.description}</p>

      <div className={styles.tags}>
        { project.tags.map(tag => <ProjectTag label={tag} key={tag} />) }
      </div>
    </Card>
  );
};
