import styles from '../styles/Home.module.scss';
import Head from 'next/head';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Hero from '../components/home/Hero';
import About from '../components/home/About';
import Contact from '../components/home/Contact';
import Projects from '../components/home/Projects/Projects';
import getImageProps from '../lib/getImageProps';
import projectsData from '../projects.json';
import Project from '../types/project';

const baseUrl = process.env.NEXT_PUBLIC_BASE_URL || (process.env.NEXT_PUBLIC_VERCEL_URL ? ('https://' + process.env.NEXT_PUBLIC_VERCEL_URL) : 'https://www.joshheng.co.uk');

export async function getStaticProps() {
  const projects: Project[] = projectsData;

  for (const project of projects) {
    if (project.image) {
      project.image = { ...project.image, ...await getImageProps(project.image.src) }
    }
  }

  return {
    props: {
      projects
    }
  }
}

interface Home {
  projects: Project[];
};

export default function Home({ projects }: Home) {
  return (
    <div className={styles.home}>
      <Head>
        <meta name='description' content="Hi, I'm Josh! Winner of Google Code-in and attendee of the Elite round of the UK Government's flagship cyber security programme CyberDiscovery, I'm a young student interested in everything technology." />
        <meta property='og:title' content='Josh Heng' />
        <meta property='og:type' content='website' />
        <meta property='og:image' content={`${baseUrl}/profile-picture.jpg`} />
        <meta property='og:description' content="Hi, I'm Josh! Winner of Google Code-in and attendee of the Elite round of the UK Government's flagship cyber security programme CyberDiscovery, I'm a young student interested in everything technology." />
      </Head>

      <Header homepage />
      <Hero />
      <About />
      <Projects projects={projects} />
      <Contact />
      <Footer />
    </div>
  );
};