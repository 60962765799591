import { useState } from 'react';
import styles from './Footer.module.scss';
import PrivacyPolicy from './PrivacyPolicy';

export default function Footer() {
  const [ showPrivacyPolicy, setShowPrivacyPolicy ] = useState(false);

  return (
    <footer className={styles.footer}>
      { showPrivacyPolicy && <PrivacyPolicy handleClose={() => setShowPrivacyPolicy(false)} /> }
      <div>
        By visiting this site you consent to the <button className="styledAnchor" style={{ fontSize: '1em' }} onClick={() => setShowPrivacyPolicy(true)}>privacy policy</button>.
      </div>
      <div>
        © Josh Heng { new Date().getUTCFullYear() }
      </div>
    </footer>
  );
};