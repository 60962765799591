import { useEffect, useState } from 'react';
import styles from './Header.module.scss';
import Image from 'next/image'
import Link from 'next/link';
import { FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { CgMenu } from 'react-icons/cg';
import ProfilePicture from '../public/profile-picture.jpg';

interface HeaderProps {
  homepage?: Boolean;
  blog?: Boolean;
};

export default function Header({ homepage = false, blog = false }: HeaderProps) {
  const [ isSticky, setIsSticky ] = useState(false);
  const [ isVisible, setIsVisible ] = useState(false);

  const checkIsSticky = () => {
    if (document.body.scrollTop > 30 || document.documentElement.scrollTop > 30) setIsSticky(true);
    else setIsSticky(false);
  }

  useEffect(() => {
    checkIsSticky();

    window.addEventListener('scroll', checkIsSticky)
    setIsVisible(false);

    return () => window.removeEventListener('scroll', checkIsSticky)
  }, []);

  return (
    <header className={`${styles.header} ${isSticky ? styles.stickyHeader : ''}`}>
      <Link href={homepage ? '/#home' : '/' } scroll={true}>
        <a className={styles.logo}>
          <Image src={ProfilePicture} alt="A profile picture of Josh Heng" width={50} height={50} placeholder="blur" priority />
          <p>Josh <br />Heng</p>
        </a>
      </Link>

      <nav>
        <ul className={`${styles.navLeft} ${isVisible ? styles.visible : ''}`}>
          <li><Link href="/#about"><a>About</a></Link></li>
          <li><Link href="/#projects"><a>Projects</a></Link></li>
          <li><Link href="/#contact"><a>Contact</a></Link></li>
          <li className={blog ? styles.activePage : ''}><Link href="/blog" scroll={true}><a>Blog</a></Link></li>
        </ul>

        <ul className={styles.navRight}>
          <li><a href="https://twitter.com/Josh_Heng1" target="_blank" rel="noopener noreferrer"><FaTwitter /><span className="visually-hidden">Twitter</span></a></li>
          <li><a href="https://github.com/JoshHeng" target="_blank" rel="noopener noreferrer"><FaGithub /><span className="visually-hidden">GitHub</span></a></li>
          <li><a href="https://www.linkedin.com/in/joshheng/" target="_blank" rel="noopener noreferrer"><FaLinkedin /><span className="visually-hidden">LinkedIn</span></a></li>
        </ul>
      </nav>

      <button className={styles.menuToggle} onClick={() => setIsVisible(visible => !visible)}>
        <CgMenu />
      </button>

      <div className={`${styles.menuToggleOff} ${isVisible ? styles.visible : ''}`} onClick={() => setIsVisible(false)} />
    </header>
  )
}