import { ReactNode } from 'react';
import styles from './CardLayout.module.scss';

interface CardLayoutProps {
  children: ReactNode;
};

export default function CardLayout({ children }: CardLayoutProps) {
  return (
    <div className={styles.projects}>
      { children }
    </div>
  );
};
