import {
  FaNodeJs, FaPhp, FaPython, FaLaravel, FaReact, FaUnity,
  FaDiscord, FaCloudflare, FaAppStore, FaGooglePlay, FaDocker, FaRaspberryPi,
  FaRegLightbulb, FaCog, FaHammer, FaGlobe, FaWordpress, FaUserGraduate, FaJava, FaCode, FaTv,
} from 'react-icons/fa';
import Tag from '../../tags/Tag';
import {HiLightningBolt} from 'react-icons/hi';
import {RiNextjsFill} from 'react-icons/ri';
import {BiLogoTypescript} from 'react-icons/bi';
import {DiHaskell, DiProlog} from 'react-icons/di';

interface TagProps {
  label: string;
}

export default function ProjectTag({ label }: TagProps) {
  switch (label) {
  /**
   * Types
   */
  case 'iot':
    return <Tag style={{ backgroundColor: '#e39220', borderColor: '#c98018' }}><FaRegLightbulb />Internet of Things</Tag>
  case 'website':
    return <Tag style={{ backgroundColor: '#0a8cd1', borderColor: '#0b7cb8' }}><FaGlobe />Website</Tag>


  /**
   * Programing Languages
   */
  case 'nodejs':
    return <Tag style={{ backgroundColor: '#6CC24A', borderColor: '#44883E' }}><FaNodeJs />Node JS</Tag>
  case 'php':
    return <Tag style={{ backgroundColor: '#8892BE', borderColor: '#4F5B93' }}><FaPhp />PHP</Tag>
  case 'python':
    return <Tag style={{ backgroundColor: '#4584B6', borderColor: '#3E75A3' }}><FaPython />Python</Tag>
  case 'typescript':
    return <Tag style={{ backgroundColor: '#4584B6', borderColor: '#3E75A3' }}><BiLogoTypescript />TypeScript</Tag>
  case 'c':
    return <Tag style={{ backgroundColor: '#316bbf', borderColor: '#346bbd' }}><FaCode />C</Tag>
  case 'java':
    return <Tag style={{ backgroundColor: '#c76319', borderColor: '#d46d22' }}><FaJava />Java</Tag>
  case 'prolog':
    return <Tag style={{ backgroundColor: '#d94511', borderColor: '#e53c00' }}><DiProlog />Prolog</Tag>
  case 'haskell':
    return <Tag style={{ backgroundColor: '#5c2c85', borderColor: '#694686' }}><DiHaskell />Haskell</Tag>



  /**
   * Frameworks
   */
  case 'laravel':
    return <Tag style={{ backgroundColor: '#F55247', borderColor: '#F4372A' }}><FaLaravel />Laravel</Tag>
  case 'react':
    return <Tag style={{ backgroundColor: '#00BFE0', borderColor: '#00ADCC' }}><FaReact />React</Tag>
  case 'wordpress':
    return <Tag style={{ backgroundColor: '#23282d', borderColor: '#171E26' }}><FaWordpress />WordPress</Tag>
  case 'nextjs':
    return <Tag style={{ backgroundColor: '#1e2123', borderColor: '#171717' }}><RiNextjsFill />NextJS (React)</Tag>

  case 'unity':
    return <Tag style={{ backgroundColor: '#222C37', borderColor: '#171E26' }}><FaUnity />Unity</Tag>


  /**
   * Technologies
   */
   case 'docker':
    return <Tag style={{ backgroundColor: '#0DB7ED', borderColor: '#0CB5E9' }}><FaDocker />Docker</Tag>


  /**
   * Services
   */
  case 'discord':
    return <Tag style={{ backgroundColor: '#5865F2', borderColor: '#4150F1' }}><FaDiscord />Discord</Tag>
  case 'cloudflare':
    return <Tag style={{ backgroundColor: '#F38020', borderColor: '#E96F0C' }}><FaCloudflare />Cloudflare</Tag>

  case 'appstore':
    return <Tag style={{ backgroundColor: '#2997FF', borderColor: '#0071E3' }}><FaAppStore />App Store</Tag>
  case 'googleplay':
    return <Tag style={{ backgroundColor: '#0ABEFF', borderColor: '#00A5E0' }}><FaGooglePlay />Google Play</Tag>


  /**
   * Other
   */
  case 'raspberrypi':
    return <Tag style={{ backgroundColor: '#C7053D', borderColor: '#9F0430' }}><FaRaspberryPi />Raspberry Pi</Tag>
  case 'digitalsignage':
    return  <Tag style={{ backgroundColor: '#C7053D', borderColor: '#9F0430' }}><FaTv />Digital Signage</Tag>

  case 'hackathon':
    return <Tag style={{ backgroundColor: '#a60cab', borderColor: '#900994' }}><FaHammer />Hackathon</Tag>
  case 'vanadiumgames':
    return <Tag style={{ backgroundColor: '#404040', borderColor: '#393939' }}><FaCog />Vanadium Games</Tag>
  case 'shockbyte':
    return <Tag style={{ backgroundColor: '#404040', borderColor: '#393939' }}><HiLightningBolt />Shockbyte</Tag>
  case 'coursework':
    return <Tag style={{ backgroundColor: '#8f77df', borderColor: '#8466e8' }}><FaUserGraduate />Coursework</Tag>

  default:
    return <Tag>{label}</Tag>
  }
}