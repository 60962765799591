import Project from './Project';
import ProjectData from '../../../types/project';
import CardLayout from '../../cards/CardLayout';

interface Projects {
  projects: ProjectData[];
};

export default function Projects({ projects }: Projects) {
  return (
    <section id="projects" style={{ scrollMarginTop: '6em' }}>
      <h2>My Projects</h2>
      <p className="content">Here are a selection of my projects. You can also find me on <a href="https://github.com/JoshHeng" target="_blank" rel="noopener noreferrer">GitHub.</a></p>

      <CardLayout>
        { projects.map(project => <Project key={project.name} project={project} />)}
      </CardLayout>
    </section>
  );
};