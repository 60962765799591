import styles from './PrivacyPolicy.module.scss';
import { MdOutlineClose } from 'react-icons/md';

interface PrivacyPolicyProps {
  handleClose: any;
};

export default function PrivacyPolicy({ handleClose }: PrivacyPolicyProps) {
  return (
    <>
      <div className={styles.privacyPolicyBackground} onClick={handleClose} />
      <div className={`${styles.privacyPolicy} content`}>
        <button className={styles.close} onClick={handleClose}>
          <MdOutlineClose />
        </button>
        <h2>Privacy Policy</h2>
        <p>This website is operated by Josh Heng and is commited to protecting and reserving the privacy of site visitors.</p>
        <p>When you visit this site, essential cookies may be stored within your browser to improve your browsing experience.</p>
        <p>This site also uses a third party called <a href="https://posthog.com/privacy" target="_blank" rel="noopener noreferrer">PostHog Cloud</a> to provide website analytics. This helps me understand how the site is used, which helps me better tailor its content.</p>
        <p>If you have any questions, please contact me at <a href="mailto:enquiries@joshheng.co.uk" target="_blank" rel="noopener noreferrer">enquiries@joshheng.co.uk</a>.</p>
      </div>
    </>
  )
};
